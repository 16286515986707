// @flow

import { h }             from "preact";
import { PureComponent } from "shared-components";
import classNames        from "classnames";
import styles            from "./styles.scss";
import { isInvalid }     from "helpers/legacyValidationRules";
import { slideDown,
         shortDuration } from "helpers/animations";

import Input from "components/Input";

const isTooShort = (v, m) => (typeof v !== "number" || typeof m !== "number") ? false : v < m;
const isTooLong  = (v, m) => (typeof v !== "number" || typeof m !== "number") ? false : v > m;

type Props = {
  class?         : string,
  displayErrors? : boolean,
  errorMessage?  : string,
  id?            : string,
  label          : string,
  maxlength      : Number,
  minlength      : Number,
  placeholder    : string,
  value          : string,
};

export default class LegacyFloatLabel extends PureComponent {
  props: Props;

  render({ displayErrors, errorMessage, label, maxlength, minlength, placeholder, value, validateLengthAs, ...props }: Props, state: any, { t }: Context) {
    const tooShort       = isTooShort(validateLengthAs || (value ? value.length : 0), minlength);
    const tooLong        = isTooLong (validateLengthAs || (value ? value.length : 0), maxlength);
    const invalidPattern = this.props.pattern ? isInvalid(value, this.props.pattern) : false;

    const hasError = tooShort || tooLong || invalidPattern;

    return (
      <div
          class={classNames(
            this.props["class"],
            styles.block
          )}>

        <Input
            {...props}
            value={value}
            maxlength={validateLengthAs ? false : maxlength}
            minlength={validateLengthAs ? false : minlength}
            hasError={displayErrors && hasError}
            class={classNames(
              styles.input,
              {[styles.hasValue]: value}
            )}
        />

        <label
            for={this.props.id}
            class={styles.label}>

          {label || placeholder}
        </label>

        {(displayErrors && hasError) ?
          <span
              class={classNames(
                styles.message,
                styles.error,
                slideDown,
                shortDuration,
              )}>

            {errorMessage}
          </span>
        : null}
      </div>
    );
  }
}
