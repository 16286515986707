// @flow

import { Component } from "preact";
import { connect }   from "preact-redux";
import { save }     from "store-reducers/checkout";

@connect(
  state => ({
    checkoutData    : state.checkout.data,
    shippingMethod  : state.checkout.data.shippingMethod,
    shippingMethods : state.checkout.data.shippingMethods
  }),
  { save }
)
export default class ObserveShippingMethods extends Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.shippingMethods.length > 0 &&
        nextProps.shippingMethods[0].code !== nextProps.shippingMethod) {

      this.props.save({
        ...nextProps.checkoutData,
        shippingMethod: nextProps.shippingMethods[0].code
      });
    }
  }
}
