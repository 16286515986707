// @flow

import { h }             from "preact";
import { PureComponent,
         ScrollTop }     from "shared-components";
import styles            from "./styles.scss";
import classNames        from "classnames";
import { connect }       from "preact-redux";
import Helmet            from "preact-helmet";
import { Redirect }      from "react-router";
import { stutter,
         slideUp,
         fadeIn }        from "helpers/animations";
import { logout }        from "redux/customer";

@connect(
  state => ({
    loggedIn: state.customer.loggedIn,
    withCode: !!state.customer.data.code,
  }),
  dispatch => ({
    logout: withCode => dispatch(logout(withCode))
  })
)
export default class Logout extends PureComponent {
  componentWillMount() {
    if (this.props.loggedIn) {
      this.props.logout(this.props.withCode);
    }
  }

  render({ loggedIn }: Props, state: any, { t }: Context) {
    if (!loggedIn) {
      return <Redirect to="/login" />
    }

    return (
      <div class={styles.block}>
        <div class={styles.body}>
          <div>
            <h1 class={slideUp}>
              {t("LOGOUT.HEADING")}
            </h1>

            <p  style={stutter(10)}
                class={classNames(
                  "lead",
                  fadeIn
                )}>

              {t("LOGOUT.INFORMATION")}
            </p>
          </div>
        </div>

        <ScrollTop />

        <Helmet title={t("LOGOUT.TITLE")} />
      </div>
    );
  }
}
