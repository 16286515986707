// @flow

import { push,
         LOCATION_CHANGE }           from "react-router-redux";
import { setActionDelay }            from "shared-components";
import { loadView,
         VIEW_LOADED }               from "store-reducers/view";
import { hintProduct }               from "store-reducers/product";
import { LOADED as CUSTOMER_LOADED } from "./customer";

export const IDLE         = "store/animate/IDLE";
export const PENDING      = "store/animate/PENDING";
export const LIST_ITEM    = "store/animate/LIST_ITEM";
export const PRODUCT_VIEW = "store/animate/PRODUCT_VIEW";
export const LOGIN        = "store/animate/LOGIN";

const initialState = {
  state   : IDLE,
  data    : {},
  initial : true,
};

let locationChangeCounter = 0;

export default function reducer(state: ReduxData<*> = initialState, action: ReduxAction = {}) {
  switch (action.type) {
    case LIST_ITEM:
    case PRODUCT_VIEW:
    case LOGIN:
      return {
        ...state,
        state : action.type,
        data  : action.data
      };

    case IDLE:
      return initialState;

    case PENDING:
      return {
        ...state,
        state: action.type
      };

    case LOCATION_CHANGE: {
      locationChangeCounter++;

      return {
        ...state,
        initial: locationChangeCounter > 1 ? false : true
      };
    }

    default:
      return state;
  }
}

export const animateListItem = (product: TProduct) => (dispatch: Function) => {
  dispatch({
    type : LIST_ITEM,
    data : product
  });

  dispatch(setActionDelay(VIEW_LOADED, 600, (dispatch: Function) => {
    dispatch(hintProduct(product));
    dispatch(push(`/${product.urlKey}`));
    dispatch(resetAnimationState);
  }));

  dispatch(loadView(`/${product.urlKey}`));
};

export const animateProductView = (product: TProduct) => (dispatch: Function) => {
  dispatch({
    type : PRODUCT_VIEW,
    data : product
  });

  setTimeout(() => dispatch(resetAnimationState), 800);
};

export const animateLogin = () => (dispatch: Function) => {
  dispatch({
    type : LOGIN
  });

  setTimeout(() => dispatch(resetAnimationState), 400);
}

export const pendingAnimation    = { type: PENDING };
export const resetAnimationState = { type: IDLE };
