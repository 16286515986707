// @flow @jsx h

import { h }             from "preact";
import { PureComponent } from "shared-components";
import { stutter, slideUp } from "helpers/animations";
import classNames from 'classnames';

import Button         from "components/Button";
import Dropdown from "components/Dropdown";

import styles from './styles.scss';

type Category = {
  name: string,
  id: number
}

type Props = {
  categories: Array<Category>,
  activeId: number,
  onClick: function,
  class: string
}


export default class CategorySelection extends PureComponent {
  onButtonClick = (category: Category) => {
    this.props.onClick(category.id)
  }

  render({ categories, activeId, onClick, ...props }: Props, _state: any, { t }: Context) {
    return (
      <div class={classNames(props['class'], slideUp)}>

        <Dropdown
          class={styles.dropdown}
          options={categories}
          onSelect={this.onButtonClick}
          value={activeId}
          labelKey="name"
          valueKey="id"
          label={t('CATEGORY_SELECTION.DROPDOWN_LABEL')}/>

        <div class={styles.categoryButtons}>
          {categories.map(c => {
            return (
              <Button
                class={classNames(styles.button, { [styles['button__active']]: c.id === activeId })}
                onClick={() => this.onButtonClick(c)}>
                {c.name.toUpperCase()}
              </Button>
              )})
            }
        </div>

      </div>
    )
  }
}
