export function getProductMeta(product) {
  const description = product.metaDescription || product.shortDescription,
        title       = product.metaTitle       || product.name,
        image       = product.smallImage      || product.largeImage || product.originalImage;

  const meta = {
    title : title,
    data  : [{ property: "og:title", content: title }],
    link  : [{ rel: "canonical", href: `/${product.urlKey}` }]
  };

  if (description) {
    meta.data.push(
      { name     : "description",    content : description },
      { property : "og:description", content : description }
    );
  }

  if (image) {
    meta.data.push(
      { property: "og:image", content: image }
    );
  }

  return meta;
}

export function getCategoryMeta(category) {
  const title       = category.metaTitle       || category.name,
        description = category.metaDescription || category.description

  const meta = {
    title : title,
    data  : [{ property: "og:title", content: title }]
  };

  if (description) {
    meta.data.push(
      { name     : "description",    content : description },
      { property : "og:description", content : description }
    );
  }

  return meta;
}

export function getCmsMeta(page) {
  const title       = page.metaTitle || page.title,
        description = page.metaDescription;

  const meta = {
    title : title,
    data  : [{ property: "og:title", content: title }]
  }

  if (description) {
    meta.data.push(
      { name:     "description",    content: description },
      { property: "og:description", content: description }
    );
  }

  return meta;
}

export function getDefaultMeta(t) {
  return [
    { charset: "utf-8" },
    { "http-equiv": "x-ua-compatible",  content: "ie=edge" },
    { name: "viewport",                 content: "width=device-width, initial-scale=1"},
    { property: "og:card",              content: "summary" },
    { property: "og:site",              content: "@julklappsvalet" },
    { property: "og:creator",           content: "@crossroads" },
    { name: "msapplication-config",     content: "/browserconfig.xml" },
    { name: "theme-color",              content: "#ffffff" },
    { property: "og:locale",            content: t("LOCALE.ISO.IETF") },
    { name:     "description",          content: t("META.DESCRIPTION") },
    { property: "og:site_name",         content: t("META.NAME") },
    { property: "og:title",             content: t("META.TITLE") },
    { name: "google-site-verification", content: t("META.GOOGLE_SITE_OWNER")},
  ];
}

export function getDefaultLink(t) {
  return [
    { rel: "apple-touch-icon", sizes: "180x180", href: "/apple-touch-icon.png" },
    { rel: "icon", type: "image/png", href: "/favicon-32x32.png", sizes: "32x32" },
    { rel: "icon", type: "image/png", href: "/favicon-16x16.png", sizes: "16x16" },
    { rel: "manifest", href: "/manifest.json" },
    { rel: "mask-icon", href: "/safari-pinned-tab.svg", color: "#7884eb" },
    { rel: "shortcut icon", href: "/favicon.ico" }
  ];
}
