// @flow

import { h }             from "preact";
import { PureComponent } from "shared-components";
import classNames        from "classnames";
import styles            from "./styles.scss";

type Props = {
  id?:          string,
  autoComplete: boolean,
  class?:       string,
  disabled?:    boolean,
  handleBlur?:  Function,
  hasError?:    boolean,
  onChange?:    Function,
  onInput?:     Function,
  onKeyDown?:   Function,
  onKeyPress?:  Function,
  placeholder?: string,
  type?:        string,
  value?:       string
};

export default class Input extends PureComponent {
  props: Props;

  static defaultProps = {
    element   : "input",
    modifiers : ""
  }

  componentDidMount() {
    if (this.props.autoFocus && this.base) {
      this.base.focus();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.autoFocus && !this.props.autoFocus && this.base) {
      this.base.focus();
    }
  }

  render({ dirty, element: Elem, errors, eventListenerType, hasError, modifiers, submitted, ...props }: Props) {
    return (
      <Elem
          {...props}
          className={classNames(
            this.props["class"],
            styles.field,
            ...modifiers.split(" ").map(m => styles[m]),
            {[styles.invalid]: hasError}
          )}
      />
    );
  }
}
