// @flow

const emailPattern = /\S+@\S+\.\S{2,}/,
      phonePattern = /^(?=.*[0-9])[- +()0-9]+$/;

export function isNumeric(value: string): boolean {
  return !isNaN(parseFloat(value)) && isFinite(value);
}

export function isEmail(value: string): boolean {
  return emailPattern.test(value);
}

export function isPhone(value: string): boolean {
  return phonePattern.test(value);
}

export function hasContent(value) {
  return typeof value !== "undefined" && value !== null && value !== "";
}

export function isInvalid(v, p) {
  return (!v || (v && !v.match(p)));
}
