// @flow

import { h }             from "preact";
import { PureComponent } from "shared-components";
import styles            from "./styles.scss";
import classNames        from "classnames";

import Wrapper from "components/Wrapper";

type Props = {
  class?: string
};

export default class OldBrowser extends PureComponent {
  props: Props;

  state = {
    render: false
  }

  render(props: Props, { render }: any, { t }: Context) {
    return render ? (
      <div
          class={classNames(
            this.props["class"],
            styles.block
          )}>

        <Wrapper>
          <p  dangerouslySetInnerHTML={{
                __html: t("OLD_BROWSER.INFO")
          }} />
        </Wrapper>
      </div>
    ) : null;
  }
}
