// @flow

import { h, Component }      from "preact";
import { connect }           from "preact-redux";
import { LOADING, loadView } from "store-reducers/view";
import { Redirect }          from "react-router";

import NotFound    from "containers/NotFound";
import ServerError from "containers/ServerError";
import CmsPage     from "containers/CmsPage";
import ProductView from "containers/ProductView";

@connect(
  state    => ({
    hintedUrl : state.view.hintedUrl,
    loggedIn  : state.customer.loggedIn,
    pathname  : state.view.pathname,
    redirect  : state.view.redirect,
    loading   : state.view.state === LOADING,
    type      : state.view.type,
  }),
  dispatch => ({
    loadView  : path => dispatch(loadView(path))
  })
)
export default class RouteView extends Component {
  updateUrl(location, currentPathname, loading) {
    if (location.pathname !== currentPathname && !loading) {
      this.props.loadView(location.pathname + location.search);
    }
  }

  componentWillMount() {
    this.updateUrl(this.props.location, this.props.pathname, false);
  }

  componentWillReceiveProps(nextProps) {
    this.updateUrl(nextProps.location, nextProps.pathname, nextProps.loading);
  }

  render({ loggedIn, loading, redirect, type }) {
    switch(type) {
      case "category" : return loggedIn ? <NotFound /> : <Redirect to="/login" />;
      case "cmspage"  : return <CmsPage />;
      case "error"    : return <ServerError />;
      case "product"  : return <ProductView />;
      case "redirect" : return <Redirect to={redirect.url} />;

      default: return loading || !type ? null : loggedIn ? <NotFound /> : <Redirect to="/login" />;
    }
  }
}
