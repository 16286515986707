// @flow

import { h }             from "preact";
import { PureComponent } from "shared-components";
import styles            from "./styles.scss";
import classNames        from "classnames";
import { connect }       from "preact-redux";
import { slideLeftFull,
         fadeOut }       from "helpers/animations";
import { close }         from "store-reducers/messages";

type TMessage = {
  code   : string,
  id     : number,
  status : string
};

type Props = {
  messages : Array<TMessage>,
  timeout  : number,
  dispatch : Dispatch
};

@connect(
  state => ({
    messages: state.messages
  }),
  dispatch => ({
    removeMessage: id => dispatch(close(id))
  })
)
export default class SystemMessages extends PureComponent {
  props: Props;

  remove = (id: number) => this.props.removeMessage(id);

  render({ messages }: Props, state: any, context: Context) {
    return (
      <aside
          class={classNames(
            styles.block,
            {[styles.hidden]: messages.length > 0}
          )}>

        <ul class={styles.list}
            transitionEnter={false}>

          {messages.map(message =>
            <Message
                key={message.id}
                message={message}
                remove={this.remove}
                class={slideLeftFull}
            />
          )}
        </ul>
      </aside>
    );
  }
}

class Message extends PureComponent {
  remove = (id: number) => this.props.remove(this.props.message.id);

  componentDidMount() {
    setTimeout(this.remove, 6000);
  }

  render({ message }: Props, state: any, { t }: Context) {
    return (
      <li class={classNames(
            this.props["class"],
            styles.item
          )}>

        <p>
          {message.code ? t(`STATUS_CODE.${message.code}`) : t("STATUS_CODE.UNKNOWN")}
        </p>
      </li>
    );
  }
}
