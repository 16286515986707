// @flow

import { h }             from "preact";
import { PureComponent } from "shared-components";
import styles            from "./styles.scss";
import classNames        from "classnames";
import { Link }          from "react-router-dom";

import Wrapper from "components/Wrapper";

type Props = {
  class?: string
};

export default class AppFooter extends PureComponent {
  props: Props;

  render(props: Props, state: any, { t }: Context) {
    return (
      <footer
          {...props}
          class={classNames(
            this.props["class"],
            styles.block
          )}>

        <Wrapper>
          <p  class={styles.copyright}
              dangerouslySetInnerHTML={{
                __html: t("FOOTER.COPYRIGHT", { currentYear: new Date().getFullYear() })
          }} />

          <Link
            to={t("ROUTE.TERMS")}
            class={styles.link}>
            {t("FOOTER.TERMS_LINK_TEXT")}
          </Link>

          {" | "}

          <Link
            to={t("ROUTE.PRIVACY")}
            class={styles.link}>
            {t("FOOTER.PRIVACY_POLICY_LINK_TEXT")}
          </Link>
        </Wrapper>
      </footer>
    );
  }
}
