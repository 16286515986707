// @flow

import { h }             from "preact";
import { PureComponent } from "shared-components";
import styles            from "./styles.scss";
import classNames        from "classnames";
import { connect }       from "preact-redux";
import Button            from "components/Button";
import Checkbox          from "components/Checkbox";

import { load      as loadTerms,
         set       as setTerms,
         setActive as setActiveTerm,
         PENDING   as TERMS_PENDING } from "store-reducers/terms";

import { toggle as toggleModal } from "store-reducers/modals";


type Props = {
  class?        : string,
  loading?      : boolean,
  setActiveTerm : Function,
  setTerms      : Function,
  termsPending  : boolean,
  termStatus    : Object,
  toggleModal   : Function,
};

@connect(
  state => ({
    terms        : state.terms.data,
    termsPending : state.terms.state === TERMS_PENDING,
    termsStatus  : state.terms.status,
  }),
  dispatch => ({
    loadTerms     : ()     => dispatch(loadTerms(false)),
    setActiveTerm : t      => dispatch(setActiveTerm(t)),
    setTerms      : (n, v) => dispatch(setTerms(n, v)),
    toggleModal   : ()     => dispatch(toggleModal("TERMS_LIGHTBOX")),
  })
)
export default class Terms extends PureComponent {
  props: Props;

  setTerms = ({ target }: Event) => {
    this.props.setTerms(target.name.replace("term_", ""), target.checked)
  };

  componentWillMount() {
    if (this.props.termsPending) {
      this.props.loadTerms();
    }
  }

  render({ loading, setActiveTerm, terms, termsStatus, toggleModal }: Props) {
    if (terms.length > 0) {
      return (
        <div
            class={classNames(
              this.props["class"],
              styles.block
            )}>

          <div class={styles.contain}>
            {terms.map(term =>
              <TermItem
                  term={term}
                  setTerms={this.setTerms}
                  setActiveTerm={setActiveTerm}
                  toggleModal={toggleModal}
                  checked={termsStatus[term.id]}
                  disabled={loading}
              />
            )}
          </div>
        </div>
      );
    }
  }
}

class TermItem extends PureComponent {
  handleClick = event => {
    event.stopPropagation();

    if (!this.props.disabled) {
      this.props.setActiveTerm(this.props.term);
      this.props.toggleModal();
    }
  }

  render({ checked, disabled, term, setTerms }: Props, state: any, { t }: Context) {
    return (
      <Checkbox
          value={checked}
          disabled={disabled}
          onChange={setTerms}
          name={`term_${term.id}`}
          required={true}
          class={styles.checkbox}>

        <div class={styles.label}>
          <label for={`term_${term.id}`}>
            {t("TERMS.I_ACCEPT") + " "}
          </label>

          <button
              type="button"
              onClick={this.handleClick}
              class={classNames(
                "button",
                styles.button
              )}>

            <span>
              {term.checkboxText.toLowerCase()}
            </span>
          </button>
        </div>
      </Checkbox>
    );
  }
}
