// @flow

import { Component,
         cloneElement,
         h }           from "preact";
import { connect }     from "preact-redux";
import { close }       from "store-reducers/modals";

type Props = {
  close:    (a: number) => void,
  modalId:  string,
  open:     boolean,
  children: ?[VNode],
  render:   (open: boolean, closeCb: () => void, registerModalElement: (el: Element) => void) => VNode,
};

type ModalData = Function & { modalId: string };

/*
  All modal-type components should be wrapped in this
  to get listeners attached to them
  automatically.
*/
@connect((state, props) => ({
  open: state.modals.indexOf(props.modalId) !== -1
}), { close })
export default class extends Component {
  props     : Props;
  modalElem : ?Element;

  handleClick = (event: Event) => {
    if (!this.props.open) {
      return;
    }

    // FIXME: We can't rely on preact internals
    const el = typeof this.modalElem === "object" && typeof this.modalElem.base === "object" ? this.modalElem.base : this.modalElem;

    let isToggler = false;
    let target    = event.target;

    while (target && target.nodeType === Node.ELEMENT_NODE) {
      if (target === el || target.getAttribute("data-toggles") === this.props.modalId) {
        return;
      }

      target = target.parentNode;
    }

    this.close();
  }

  handleKeydown = (event: Event) => {
    if (event.metaKey || event.ctrlKey || event.altKey || event.shiftKey) {
      return;
    }

    if (event.which === 27) {
      this.close();
    }
  }

  close = () => {
    if (this.props.open) {
      document.activeElement && document.activeElement.blur && document.activeElement.blur();

      this.props.close(this.props.modalId);
    }
  }

  registerModalElement = (elem: Element) => this.modalElem = elem;

  componentDidMount() {
    document.addEventListener("keydown",  this.handleKeydown, { capture: true, passive: true });
    document.addEventListener("click",    this.handleClick,   { capture: true, passive: true });
    document.addEventListener("touchend", this.handleClick,   { capture: true, passive: true });

    if (document.scrollingElement) {
      document.scrollingElement.style.setProperty("overflow", "hidden");
    }
  }

  componentWillUnmount() {
    /**
      * modals can unmount from other actions than closing, such as
      * logging in or out. This clears the open modal from the array
      * of open modals to prevent possible problems.
      */

    this.close();

    document.removeEventListener("keydown",  this.handleKeydown, { capture: true, passive: true });
    document.removeEventListener("click",    this.handleClick,   { capture: true, passive: true });
    document.removeEventListener("touchend", this.handleClick,   { capture: true, passive: true });

    if (document.scrollingElement) {
      document.scrollingElement.style.removeProperty("overflow");
    }
  }

  render({ children, open, render }: Props) {
    if (render) {
      return render(open, this.close, this.registerModalElement);
    }

    if (process.env.NODE_ENV !== "production" && (!children || children.length !== 1)) {
      throw new Error("<Modal /> must have a single child, or the render-property set.");
    }

    return cloneElement(children[0], {
      close : this.close,
      open  : open,
      ref   : this.registerModalElement,
    });
  }
}
