// @flow

import { h }             from "preact";
import { PureComponent } from "shared-components";
import styles            from "./styles.scss";
import classNames        from "classnames";
import { connect }       from "preact-redux";

import { remove as removeItem } from "store-reducers/checkout";

import Button from "components/Button";

type Props = {
  class?:        string,
  items:         Array,
  removeItem:    Function,
  removingItems: Array
};

@connect(
  state => ({
    items         : state.checkout.data.cart && state.checkout.data.cart.items,
    removingItems : state.checkout.removingItems,
  }),
  { removeItem }
)
export default class CheckoutReview extends PureComponent {
  props: Props;

  static defaultProps = {
    items: []
  }

  render({ items, removeItem, removingItems }: Props) {
    return (
      <div
          class={classNames(
            this.props["class"],
            styles.block
          )}>

        <ul>
          {items.map(item =>
            <CheckoutReviewItem
                item={item}
                removeItem={removeItem}
                removing={removingItems.indexOf(item.id) !== -1}
            />
          )}
        </ul>
      </div>
    );
  }
}

class CheckoutReviewItem extends PureComponent {
  removeItem = () => this.props.removeItem(this.props.item.id);

  render({ item, removing }: Props, state: any, { t }: Context) {
    return (
      <li class={styles.item}>
        <div class={styles.contain}>
          <img
              alt=""
              class={styles.image}
              src={item.options && item.options[0] && item.options[0].value ? item.options[0].value.thumbnail : item.product.thumbnail}
          />

          <div class={styles.information}>
            <div class={styles.name}>
              {item.product.name}

              {item.options && item.options.map(o => o.value
                ? <span>
                    {`, ${o.value.label.toLowerCase()}`}
                  </span>
                : null
              )}

              {(item.product.attributes && item.product.attributes.manufacturer) ?
                <span
                    class={classNames(
                      styles.type,
                      "subheading"
                    )}>

                  {item.product.attributes.manufacturer}
                </span>
              : null}

              {(item.customOptions && Object.keys(item.customOptions).length > 0) ?
                <div>
                  {Object.keys(item.customOptions).map(option =>
                    <i  class={classNames(
                          styles.type,
                          "subheading"
                        )}>

                      {item.customOptions[option]}
                    </i>
                  )}
                </div>
              : null }
            </div>
          </div>
        </div>

        <div class="row--small">
          <Button
              modifiers="inline"
              onClick={this.removeItem}
              disabled={removing}>

            {removing ? t("CHECKOUT.REMOVING") : t("CHECKOUT.CHANGE_GIFT")}
          </Button>
        </div>
      </li>
    );
  }
}
