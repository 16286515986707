// @flow

import { h }             from "preact";
import { PureComponent } from "shared-components";
import styles            from "./styles.scss";
import classNames        from "classnames";

import CheckmarkIcon from "icons/checkmark.svg";
import AlertIcon     from "icons/alert.svg";

export default class Message extends PureComponent {
  static defaultProps = {
    status  : "notice",
    element : "div",
  };

  render({ children, element: Elem, notice, status, ...rest }: Props) {
    return (
      <Elem
          {...rest}
          class={classNames(
            this.props["class"],
            styles.block,
            styles[status]
          )}>

        <div class={styles.media}>
          {(status === "error" || status === "notice") ?
            <AlertIcon class={styles.icon} />
          : status === "success" ?
            <CheckmarkIcon class={styles.icon} />
          : null}
        </div>

        <div class={styles.body}>
          {children}
        </div>
      </Elem>
    );
  }
}
