// @flow

import { h }              from "preact";
import { PureComponent }  from "shared-components";
import styles             from "./styles.scss";
import classNames         from "classnames";
import { connect }        from "preact-redux";
import { close }          from "store-reducers/modals";
import CrossIcon          from "icons/cross.svg";
import { set as setTerm } from "store-reducers/terms";
import Button             from "components/Button"
import Lightbox           from "components/Lightbox"


@connect(
  state => ({
    term : state.terms.activeTerm,
  }),
  dispatch => ({
    acceptTerms : id => dispatch(setTerm(id, true)),
    close       : () => dispatch(close("TERMS_LIGHTBOX")),
  })
)
export default class TermsLightbox extends PureComponent {
  context : Context;
  props   : Props;

  acceptTerms = () => {
    this.props.acceptTerms(this.props.term.id);
    this.props.close();
  }

  componentDidMount() {
    this.article && this.article.focus();
  }

  render({ close, term }: Props, state: any, { t }: Context) {
    return (
      <Lightbox
          modalId="TERMS_LIGHTBOX"
          class={classNames(
            this.props["class"],
            styles.block,
          )}>

        <header class={styles.header}>
          <h1 class={styles.heading}>
            {term.name}
          </h1>

          <button
              type="button"
              onClick={close}
              class={classNames(
                "button",
                styles.close
              )}>

            <CrossIcon class="icon" />
          </button>
        </header>

        <article
            ref={elem => this.article = elem}
            dangerouslySetInnerHTML={{ __html: term.content }}
            tabindex="-1"
            class={classNames(
              styles.body,
              "typography"
            )}>
        </article>

        <footer class={styles.footer}>
          <Button
              modifiers="primary"
              onClick={this.acceptTerms}>

            {t("TERMS.I_ACCEPT")}
          </Button>
        </footer>
      </Lightbox>
    );
  }
}
