// @flow

import { h }             from "preact";
import { PureComponent } from "shared-components";
import styles            from "./styles.scss";
import classNames        from "classnames";
import { slideDown,
         shortDuration } from "helpers/animations";

import LegacyFloatLabel from "components/LegacyFloatLabel";

type Props = {
  class? : string,
  option : Object,
};

export default class CustomOption extends PureComponent {
  handleInput = (event: Event) => {
    if (typeof this.props.onInput === "function") {
      this.props.onInput(this.props.option.id, event.target.value);
    }
  }

  render({ option, value, ...props }: Props, state: any, { t }: Context) {
    return (
      <div class={this.props["class"]}>
        <LegacyFloatLabel
            {...props}
            class=""
            value={value}
            placeholder={option.title}
            onInput={this.handleInput}
        />

        {(option.maxLength && value && value.length > option.maxLength) ?
          <span
              class={classNames(
                styles.message,
                styles.error,
                slideDown,
                shortDuration
              )}>

            {t("CUSTOM_OPTION.ERROR_MAX", {
              max     : option.maxLength,
              current : value.length,
            })}
          </span>
        : null}
      </div>
    );
  }
}
