// @flow

import { h }                 from "preact";
import { PureComponent,
         ScrollTop }         from "shared-components";
import styles                from "./styles.scss";
import { connect }           from "preact-redux";
import Helmet                from "preact-helmet";
import { withRouter }        from "react-router-dom";
import { loadCategoryByKey,
         CATEGORY_LOADED }   from "redux/giftCategory";

import GiftCategory from "containers/GiftCategory";
import NotFound     from "containers/NotFound";

@connect(
  state    => ({
    demoLoaded : state.giftCategory.demo && state.giftCategory.state === CATEGORY_LOADED,
    error      : state.giftCategory.error,
  }),
  dispatch => ({
    loadCategory : urlKey => dispatch(loadCategoryByKey(urlKey, true))
  }),
)
export default class DemoCategory extends PureComponent {
  componentWillMount() {
    if (this.props.match.params.urlKey && (!this.props.demoLoaded)) {
      this.props.loadCategory(this.props.match.params.urlKey);
    }
  }

  render({ error }: Props, state: any, { t }: Context) {
    if (error) {
      return <NotFound />;
    }

    return (
      <div class={styles.block}>
        <GiftCategory />

        <Helmet
            title={t("DEMO_CATEGORY.TITLE")}
        />
      </div>
    );
  }
}
