// @flow

import {
  conditional,
  EMAIL,
  isEmail,
  isPhone,
  lengthGt,
  match,
  nestedRule,
  required,
} from "formaggio";

import { INVOICE_METHODS } from "helpers/paymentMethods.js";

export function emailRules(key = "email") {
  return [
    required(key),
    conditional(o => o[key], isEmail(key)),
  ];
};

export function passwordRules(key = "password", repeatKey = "repeatPassword", length = 5) {
  return [
    required(key),
    conditional(o => o[key], lengthGt(key, length)),
    conditional(o => o[key] && o[key].length > length, match(key, repeatKey))
  ];
};

export function contactRules(key = "billingAddress") {
  return [
    required(key),
    nestedRule(key, required("firstname")),
    nestedRule(key, required("lastname")),
    nestedRule(key, required("telephone")),
    nestedRule(key, conditional(o => o.telephone, isPhone("telephone"))),
  ];
}

export function addressRules(key = "billingAddress") {
  return [
    required(key),
    nestedRule(key, nestedRule("street", required("0"))),
    nestedRule(key, required("postcode")),
    nestedRule(key, required("city")),
  ];
}

export function ssnRules() {
  return [
    conditional(o => INVOICE_METHODS.indexOf(o.paymentMethod) !== -1, nestedRule("paymentMethodData", required("socialSecurityNumber")))
  ];
}

export function handleSubmitError(base, event, validations) {
  if (validations) {
    base.querySelector(`[name="${validations[0].field}"]`).focus();
  }
};

export const validEmail = email => !email || EMAIL.test(email);

export function matchesLocalePostcode(postcode, locale) {
  switch(locale) {
    case "sv-SE" : return /^\d{3}\s?\d{2}$/.test(postcode);
    case "nb-NO" : return /^[0-9]{4}$/.test(postcode);
    case "en-GB" : return /^[a-zA-Z0-9]{4,10}$/.test(postcode);
    default      : return true;
  }
}
