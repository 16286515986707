// @flow

import { h, Component } from "preact";
import styles           from "./styles.scss";
import classNames       from "classnames";
import Helmet           from "preact-helmet";
import { stutter,
         fadeIn,
         slideUp }      from "helpers/animations";
import Translation      from "helpers/translation";

import { getLogoWithLocale } from "components/AppLogo";

export default class WithContext extends Component {
  getChildContext = () => {
    const t = new Translation(this.props.translation);

    return {
      t: t.translate.bind(t)
    }
  }

  render() {
    return <ServerError />;
  }
}

class ServerError extends Component {
  render(props: Props, state: any, { t }: Context) {
    return (
      <div class={styles.block}>
        <header
            class={classNames(
              this.props["class"],
              styles.header,
            )}>

          <div class={styles.headerBar}>
            <section class={styles.headerWrapper}>
              {getLogoWithLocale(t("LOCALE.ISO.3166-1"))}
            </section>
          </div>
        </header>

        <div class={styles.wrap}>
          <main class={styles.main}>
            <div class={styles.body}>
              <div>
                <h1 class={slideUp}>
                  <span
                      style={{ display: "block" }}
                      class={slideUp}>

                    {t("SERVER_ERROR.HEADING")}
                  </span>

                  <span
                      style={stutter(1)}
                      class={classNames(
                        "subheading",
                        "text--small",
                        slideUp
                      )}>

                    {t("SERVER_ERROR.SUBHEADING")}
                  </span>
                </h1>
              </div>

              <div
                  style={{ animationDelay: "1000ms" }}
                  class={classNames(styles.contact, fadeIn)}>

                <p  dangerouslySetInnerHTML={{ __html: t("SERVER_ERROR.TEXT") }} />
              </div>
            </div>
          </main>
        </div>

        <Helmet title={t("SERVER_ERROR.TITLE")} />
      </div>
    );
  }
}
