// @flow


import { jsonResponse }     from "fetch-utilities";

export const PENDING = "store/categories/PENDING";
export const LOADING = "store/categories/LOADING";
export const LOADED  = "store/categories/LOADED";
export const ERROR   = "store/categories/ERROR";

const initialState = {
  state: PENDING,
  data: []
}

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case LOADING:
      return {
        ...state,
        state: action.type
      }
    case LOADED:
        return {
          state: action.error ? ERROR : action.type,
          data: action.error ? state.data : action.payload,
        }
    default:
      return state;
  }
}

export const load = () => (dispatch: Function, _getState: Function, client: Object) => {
  dispatch({
    type: LOADING,
  })

  dispatch({
    type: LOADED,
    payload: client("/categories").then(jsonResponse),
  })
}
