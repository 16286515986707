// @flow

import { h }       from "preact";
import styles      from "./styles.scss";
import classNames  from "classnames";
import { stutter,
         slideUp } from "helpers/animations";

const Heading = ({ heading, subheading, stutterOffset = 0, animate = true, ...props }) =>
  <h1 class={props["class"]}>
    <span
        style={stutter(stutterOffset)}
        class={classNames(
          styles.text,
          {[slideUp]: animate}
        )}>

      {heading}
    </span>

    <span
        style={stutter(stutterOffset + 1)}
        class={classNames(
          "subheading",
          {[slideUp]: animate}
        )}>

      {subheading}
    </span>
  </h1>;

export default Heading;
