// @flow

export const filterCategories = (categoryId: number) => (category: Object) => {
  return  category.includeInMenu && category.parentId == categoryId
}

export const sortCategories = (catA: Object, catB: Object) => {
  return catA.name.localeCompare(catB.name);
}

export const transformCategories = (c: Object) => ({ id: c.id, name: c.name});
