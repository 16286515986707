// @flow

import { h }             from "preact";
import { PureComponent } from "shared-components";
import classNames        from "classnames";
import styles            from "./styles.scss";

import Input      from "components/Input";
import InputError from "components/InputError";

export default class FloatLabel extends PureComponent {
  render({ dirty, errors, eventListenerType, label, submitted, ...props }: Props) {
    const displayErrors = (dirty || submitted) && errors && errors.length;

    return (
      <div
          class={classNames(
            this.props["class"],
            styles.block
          )}>

        <div>
          <Input
              {...props}
              hasError={displayErrors}
              class={classNames(
                styles.input,
                {[styles.hasValue]: this.props.value}
              )}
          />

          <label
              for={this.props.id}
              class={styles.label}>

            {label || placeholder}
          </label>
        </div>

        {displayErrors ?
          <InputError
              errors={errors}
              name={this.props.name}
              class="row--tiny"
          />
        : null}
      </div>
    );
  }
}
