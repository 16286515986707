// @flow

import { h }             from "preact";
import { PureComponent } from "shared-components";
import styles            from "./styles.scss";
import classNames        from "classnames";

type Props = {
  children:  Element,
  element?:  string,
  class?:    string,
  modifier?: string
};

export default class Wrapper extends PureComponent {
  props: Props;

  static defaultProps = {
    element   : "div",
    modifiers : "",
  }

  render({ children, modifiers, element: Elem }) {
    return (
      <Elem
          class={classNames(
            this.props["class"],
            styles["block"],
            ...modifiers.split(" ").map(m => styles[m])
          )}>

        {children}
      </Elem>
    );
  }
}
