// @flow

import { h }             from "preact";
import { PureComponent } from "shared-components";
import styles            from "./styles.scss";
import classNames        from "classnames";

import CheckmarkIcon from "icons/checkmark.svg";

export default class Checkbox extends PureComponent {
  props: Props;

  handleClick = event => {
    if (typeof this.props.onClick === "function") {
      this.props.onClick(event);
    }

    if (typeof this.props.onChange === "function") {
      this.checkbox.click();
    }
  };

  render({ children, onChange, onClick, label, required, value, ...props }: Props) {
    return (
      <div
          {...props}
          class={classNames(
            this.props["class"],
            styles.block
          )}>

        <input
            id={props.name}
            checked={value}
            name={props.name}
            onChange={onChange}
            type="checkbox"
            ref={elem => this.checkbox = elem}
            class={styles.input}
            tabindex="-1"
        />

        <button
            type="button"
            disabled={props.readOnly || props.disabled}
            onClick={this.handleClick}
            class={classNames(
              "button",
              styles.button,
              {[styles.checked]: value}
            )}>

          <CheckmarkIcon
              class={styles.icon}
          />
        </button>

        {children.length > 0 || label ?
          <label
              for={props.name}
              class={classNames(
                styles.label,
                { [styles.required]    : required,
                  [styles.interaction] : children.length === 0,
                  [styles.disabled]    : props.readOnly || props.disabled }
              )}>

            {children.length > 0 ? children : label}
          </label>
        : null}
      </div>
    );
  }
}
