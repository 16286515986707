// @flow

import { h }             from "preact";
import { PureComponent } from "shared-components";
import styles            from "./styles.scss";
import classNames        from "classnames";
import Helmet            from "preact-helmet";
import { connect }       from "preact-redux";
import { ScrollTop }     from "shared-components";
import { Redirect }      from "react-router";
import { set,
         debounce }      from "diskho";
import { stutter,
         slideUp,
         fadeIn }        from "helpers/animations";
import { conditional,
         Form,
         nestedRule,
         rules }         from "formaggio";
import { handleSubmitError,
         matchesLocalePostcode,
         addressRules,
         contactRules,
         emailRules }    from "helpers/validationRules";

import { create     as createOrder,
         hint       as hintCheckout,
         save       as saveData,
         store      as storeData,
         update     as setData,
         LOADED     as CHECKOUT_LOADED,
         LOADING    as CHECKOUT_LOADING,
         SUBMITTING as CHECKOUT_SUBMITTING,
         SUCCESS    as CHECKOUT_SUCCESS } from "store-reducers/checkout";

import Button  from "components/Button";
import Heading from "components/Heading";
import Wrapper from "components/Wrapper";
import Terms           from "components/Terms";
import TermsLightbox   from "components/TermsLightbox";


import CheckoutForm   from "components/CheckoutForm";
import CheckoutReview from "components/CheckoutReview";

import ObserveShippingMethods from "containers/ObserveShippingMethods";

const TLD_BLACKLIST = ["example.com"];

function removeBlacklistedTLDs(email) {
  return email && TLD_BLACKLIST.indexOf(email.split("@").pop()) === -1 ? email : "";
}

@connect(
  state => ({
    cart           : state.cart.data,
    checkoutData   : state.checkout.data,
    customerData   : state.customer.data,
    demoMode       : state.customer.data.test,
    hasError       : state.checkout.error,
    loaded         : state.checkout.state === CHECKOUT_LOADED,
    payment        : state.checkout.payment,
    shouldRedirect : state.checkout.state === CHECKOUT_LOADED &&
                    (state.checkout.data.cart && state.checkout.data.cart.items.length === 0),
    submitting     : state.checkout.state === CHECKOUT_SUBMITTING,
    success        : state.checkout.state === CHECKOUT_SUCCESS,

    termsStatus    : state.terms.status,
    termsOpen      : state.modals.indexOf("TERMS_LIGHTBOX") !== -1,
  }),
  { createOrder, hintCheckout, saveData, storeData, setData },
  (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    checkoutData: {
      ...stateProps.checkoutData,
      email: removeBlacklistedTLDs(stateProps.checkoutData.email)
    },
    customerData: {
      ...stateProps.customerData,
      email: removeBlacklistedTLDs(stateProps.customerData.email)
    }
  })
)
export default class Checkout extends PureComponent {
  constructor(props) {
    super(props);
    this.debouncedSaveData = debounce(this.saveData);
  }

  saveData = data => {
    if (!this.props.submitting) {
      this.props.saveData(data);
    }
  };

  handleChange = data => {
    const addressData = {
      billingAddress  : data.billingAddress,
      email           : removeBlacklistedTLDs(data.email),
      shippingAddress : data.shippingAddress
    };

    this.props.setData(addressData);

    // don't save fields with validation errors
    this.debouncedSaveData(this.validate(addressData).reduce((a, v) => set(a, v.field, null), addressData));
  };

  handleSubmit = (event: Event) => {
    event.preventDefault();

    if (this.submitDisabled()) {
      return;
    }

    this.props.createOrder({
      ...this.props.checkoutData,
      agreements: Object.keys(this.props.termsStatus)
                        .filter(key => this.props.termsStatus[key] === true)
                        .map(term => term|0)

    });
  };

  handleError = (event, validations) => handleSubmitError(this.base, event, validations);

  submitDisabled = () =>
    this.props.demoMode   ||
    this.props.submitting ||
    this.props.loading    ||
    this.props.hasError;

  loadCheckout = ({ checkoutData: data, customerData, storeData }: Props) => {
    storeData({
      billingAddress : {
        ...customerData.address,
        ...data.billingAddress,
        countryId : this.context.t("LOCALE.ISO.3166-1"),
        useAsShippingAddress: true
      },
      shippingAddress : {
        ...customerData.address,
        ...data.shippingAddress,
        countryId : this.context.t("LOCALE.ISO.3166-1"),
      },
      email: data.email || customerData.email
    });
  };

  validate = rules([
    ...contactRules(),
    ...addressRules(),
    ...emailRules(),
    nestedRule("billingAddress",
      conditional(c => !!c.postcode,
      c => matchesLocalePostcode(c.postcode, this.context.t("LOCALE.ISO.IETF")) ?
        [] :
        [{ error: "POSTCODE", field: "postcode" }]
      )
    )
  ]);

  componentWillMount() {
    if (!this.props.loaded && this.props.cart.items.length > 0) {
      this.props.hintCheckout({ cart: this.props.cart });
    }

    this.loadCheckout(this.props);
  }

  render({ checkoutData, demoMode, payment, shouldRedirect, submitting, success }: Props, state: any, { t }: Context) {
    if (shouldRedirect) {
      return <Redirect to="/" />
    }

    if (success && payment && payment.id) {
      return  <Redirect to={{
                pathname : "/checkout/success",
                search   : `?orderId=${payment.id}`,
              }} />
    }

    return (
      <div class={styles.block}>
        <ObserveShippingMethods />

        <Wrapper
            element="section">

          <header class="row--section">
            <Heading
                heading={t("CHECKOUT.HEADING")}
                subheading={t("CHECKOUT.SUBHEADING")}
            />
          </header>

          <div class={styles.contain}>
            <aside
                style={stutter(2)}
                class={classNames(
                  styles.aside,
                  fadeIn
                )}>

              <CheckoutReview />
            </aside>

            <Form
                onSubmit={this.handleSubmit}
                onError={this.handleError}
                onChange={this.handleChange}
                state={checkoutData}
                validations={this.validate(checkoutData)}
                style={stutter(3)}
                class={classNames(
                  styles.body,
                  fadeIn
                )}>

              <CheckoutForm
                  readOnly={submitting}
              />

              <div
                  class={classNames(
                    styles.row,
                    "row--huge"
                  )}>

                <div class={styles.column}>
                  <h6>
                    {t("CHECKOUT.INFO_HEADING")}
                  </h6>

                  <p class="flat">
                    {t("CHECKOUT.INFO_TEXT")}
                  </p>

                  <Terms />
                </div>

                <div
                    class={classNames(
                      styles.column,
                      styles.checkout
                    )}>

                  <Button
                      type="submit"
                      modifiers="primary"
                      disabled={this.submitDisabled()}>

                    {t("CHECKOUT.SUBMIT")}
                  </Button>

                  {demoMode ?
                    <p class={styles.demoInfo}>
                      {t("CHECKOUT.DEMO_MODE")}
                    </p>
                  : null}
                </div>
              </div>
            </Form>
          </div>
        </Wrapper>

        {this.props.termsOpen && <TermsLightbox />}

        <ScrollTop />

        <Helmet title={t("CHECKOUT.TITLE")} />
      </div>
    );
  }
}
