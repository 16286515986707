// @flow

import { h }             from "preact";
import { PureComponent } from "shared-components";
import styles            from "./styles.scss";
import classNames        from "classnames";
import { connect }       from "preact-redux";
import Helmet            from "preact-helmet";
import { ScrollTop }     from "shared-components";
import { getCmsMeta }    from "helpers/getMetaData";
import { stutter,
         fadeIn,
         slideUp }       from "helpers/animations";

import Wrapper from "components/Wrapper";

import AppFooter from "containers/AppFooter";

@connect(
  state => ({
    page    : state.cmsPage.data,
    viewUrl : state.view.url
  })
)
export default class CmsPage extends PureComponent {
  render({ page }: Props, state: any, { t }: Context) {
    const meta = getCmsMeta(page);

    return (
      <div class={styles.block}>
        <Wrapper
            element="article"
            class={styles.wrapper}>

          {page.title ?
            <h1 class="h1">
                <span
                    style={{ display: "inline-block" }}
                    class={slideUp}>
                  {page.title}
                </span>
                {page.contentHeading ?
                  <span style={stutter(2)}
                    class={classNames(
                    "subheading",
                    slideUp
                  )}>
                    {page.contentHeading}
                  </span>
              : null}
            </h1>
          : null}

          {page.html ?
            <div
                dangerouslySetInnerHTML={{ __html: page.html }}
                style={stutter(3)}
                class={classNames(
                  styles.body,
                  fadeIn,
                  "typography",
                  "row--huge"
                )}>
            </div>
          : null}
        </Wrapper>

        <AppFooter
            style={stutter(8)}
            class={classNames(
              fadeIn,
              "row--section"
            )}
        />

        <ScrollTop />

        <Helmet
            title={meta.title}
            meta={meta.data}
        />
      </div>
    );
  }
}
