// @flow

import { h }             from "preact";
import { PureComponent } from "shared-components";

import Dropdown from "components/Dropdown";

type Props = {
  class?:        string,
  option:        Object,
  selectedValue: Object
};

export default class ProductOption extends PureComponent {
  setOption = (selectedValue: Object) => this.props.setOption(this.props.option.id, selectedValue);

  render({ option, selectedValue, ...rest }: Props, state: any, { t }: Context) {
    return (
      <Dropdown
          {...rest}
          onSelect={this.setOption}
          options={option.values}
          value={selectedValue || null}
          labelKey="label"
          valueKey="id"
          label={t("PRODUCT.PLEASE_CHOOSE", { attr: option.title.toLowerCase() })}
      />
    );
  }
}
