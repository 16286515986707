// @flow

import { h }             from "preact";
import { PureComponent } from "shared-components";
import styles            from "./styles.scss";
import classNames        from "classnames";
import { Link }          from "react-router-dom";

type Props = {
  children  : Element,
  class?    : string,
  disabled  : boolean,
  modifiers : string,
  onClick   : Function,
  title     : string,
  type      : string,
};

export default class Button extends PureComponent {
  props: Props;

  static defaultProps = {
    modifiers: ""
  }

  render({ children, elem, modifiers, type, ...props }: Props, state: any, { t }: Context) {
    const Elem = elem || (this.props.to ? Link : this.props.href ? "a" : "button");

    return (
      <Elem
          {...props}
          type={type || (this.props.to || this.props.href ? false : "button")}
          class={classNames(
            "button",
            {["disabled"]: this.props.disabled },
            this.props["class"],
            ...modifiers.split(" ")
          )}>

        <span>
          {children}
        </span>
      </Elem>
    );
  }
}
