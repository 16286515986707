// @flow

import { h }             from "preact";
import { PureComponent } from "shared-components";
import styles            from "./styles.scss";
import classNames        from "classnames";
import { slideDown }     from "helpers/animations";

import CrossIcon from "icons/cross.svg";

import Button from "components/Button";

type Props = {
  class?: string,
};

export default class DemoBar extends PureComponent {
  props: Props;

  close = () => {
    this.setState({
      closed: true
    });
  }

  render(props: Props, { closed }: any, { t }: Context) {
    if (closed) {
      return null;
    }

    return (
      <aside
          {...props}
          class={classNames(
            this.props["class"],
            styles.block,
            slideDown
          )}>

        <div class={styles.media}>
          <Button
              onClick={this.close}
              class={styles.button}>

            <CrossIcon class={styles.icon} />

            <span class={styles.close}>
              {t("DEMO.CLOSE")}
            </span>
          </Button>
        </div>

        <div class={styles.body}>
          <h4 class={styles.heading}>
            {t("DEMO.HEADING")}
          </h4>

          <div
              class={classNames(
                styles.text,
                "text-row--mini"
              )}>

            <p dangerouslySetInnerHTML={{ __html: t("DEMO.INFO") }}/>
          </div>

          <Button href={t("EXTERNAL.ACQUIRE_URL")}
                  modifier="primary small slim"
                  class={styles.button}
          >
            Beställ gåvokort
          </Button>
        </div>
      </aside>
    );
  }
}
