// @flow

import { h }      from "preact";
import styles     from "./styles.scss";
import classNames from "classnames";

export default function Hamburger({ open, ...props }) {
  return (
    <button
        {...props}
        type="button"
        class={classNames(
          props["class"],
          styles.block,
          {[styles.active]: open}
        )}>

      <span class={styles.icon} />
    </button>
  );
};
