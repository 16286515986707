// Acquire redeem master
module.exports = {
  host: "localhost",
  port: 9101,
  apiHost: {
    server       : "http://127.0.0.1/api",
    client       : "/api",
    proxyHeaders : ["SSL", "Ssl-Offloaded", "X-Forwarded-Proto", "Host", "X-Real-Ip", "X-Forwarded-For"],
  },
  cspUri: null,
  isProduction: true
};
