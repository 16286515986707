 // @flow

import { h }             from "preact";
import { PureComponent } from "shared-components";
import styles            from "./styles.scss";
import classNames        from "classnames";
import AlertIcon         from "icons/alert.svg";

type Props = {
  class?:    string,
  modifiers: string
};

export default class InformationBar extends PureComponent {
  props: Props;

  static defaultProps = {
    modifiers: ""
  }

  render({ children, modifiers, ...props }: Props) {
    const mods = modifiers.split(" ");

    return (
      <div
          {...props}
          class={classNames(
            this.props["class"],
            styles.block,
            ...mods.map(m => styles[m])
          )}>

        {mods.indexOf("text") !== -1 ?
          <AlertIcon
              class={styles.icon}
          />
        : null}

        <div class={styles.body}>
          {children}
        </div>
      </div>
    );
  }
}
