// @flow

import { push }                      from "react-router-redux";
import { isPromise }                 from "diskho";
import { LOADED as CHECKOUT_LOADED,
         reset  as resetCheckout,
         store  as storeCheckoutData,
         STORED as CHECKOUT_STORED } from "store-reducers/checkout";
import { LOGOUT }                    from "redux/customer.js";

const sideEffectsMiddleware = store => next => action => {
  next(action);

  if (!isPromise(action.payload)) {
    return;
  }

  if (process.browser) {
    action.payload.then(x => x, data => {
      if (data && data.errorCode === 3000) {
        next(push(t("ROUTE.LOGOUT")));
      }
    });
  }

  if (action.type === CHECKOUT_LOADED ||
      action.type === CHECKOUT_STORED) {
    return action.payload.then(resp => {
      if (!resp.shippingMethod && resp.shippingMethods.length > 0 && !resp.cart.summary.virtual) {
        next(storeCheckoutData({
          shippingMethod: resp.shippingMethods[0].code
        }));
      }

      if (!resp.paymentMethod && resp.paymentMethods.length > 0) {
        next(storeCheckoutData({
          paymentMethod: resp.paymentMethods[0].code
        }));
      }
    });
  }

  if (action.type === LOGOUT) {
    return action.payload.then(() => {
      next(resetCheckout());
    });
  }
};

export default sideEffectsMiddleware;
