// @flow

import { h }             from "preact";
import { PureComponent } from "shared-components";
import styles            from "./styles.scss";
import classNames        from "classnames";
import Modal             from "components/Modal/index.js";
import Button            from "components/Button";

export default class Lightbox extends PureComponent {
  render({ children, modalId }: Props) {
    return (
      <Modal modalId={modalId}>
        <aside
            class={classNames(
              this.props["class"],
              styles.block
            )}>

          <div class={styles.container}>
            <div class={styles.body}>
              {children}
            </div>
          </div>
        </aside>
      </Modal>
    );
  }
}
