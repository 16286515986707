// @flow

import { h }             from "preact";
import { PureComponent } from "shared-components";
import styles            from "./styles.scss";
import classNames        from "classnames";
import { Link }          from "react-router-dom";

type Props = {
  children: Element,
  class?:   string
};

export default class PrimaryNav extends PureComponent {
  props:   Props;

  render({ children }: Props, { t }: Context) {
    if (!children) {
      return;
    }

    return (
      <nav
          class={classNames(
            this.props["class"],
            styles.block
          )}>

        <ul class={styles.list}>
          {children.map((child, i) =>
            <li class={styles.item}>
              {child}
            </li>
          )}
        </ul>
      </nav>
    );
  }
}
