// @flow

import { h }             from "preact";
import { PureComponent } from "shared-components";
import styles            from "./styles.scss";
import classNames        from "classnames";
import { stutter,
         slideUp }       from "helpers/animations";

import ProductCard from "components/ProductCard";

type Props = {
  categoryUrl:         string,
  class?:              string,
  listName?:           string,
  products:            Array<TProduct>,
  stutterTimingOffset: Number
};

export default class ProductList extends PureComponent {
  props: Props;

  static defaultProps = {
    categoryUrl         : "",
    stutterTimingOffset : 0,
    noStutter           : false,
    shouldAnimate       : true,
  }

  render({ categoryUrl, listName, noStutter, products, stutterTimingOffset, style, shouldAnimate }: Props, state: any, { t }: Context) {
    if (products.length > 0) {
      return (
        <div style={style}
            class={classNames(
              this.props["class"],
              styles.block
            )}>

          <ul class={styles.list}>
            {products.map((product, i) =>
              <li itemProp="itemListElement"
                  style={noStutter ? null : stutter(i + stutterTimingOffset, 20)}
                  class={classNames(
                    styles.item,
                    { [slideUp]       : shouldAnimate }
                  )}>

                <ProductCard
                    product={product}
                    position={i}
                    listName={listName}
                    categoryUrl={categoryUrl}
                    shouldAnimate={shouldAnimate}
                />
              </li>
            )}
          </ul>
        </div>
      );
    }
  }
}
