import { routerReducer as router } from "react-router-redux";

import animate        from "./animate";
import cart           from "store-reducers/cart";
import checkout       from "store-reducers/checkout";
import cmsPage        from "./cmsPage";
import cmsPages       from "store-reducers/cmsPages";
import customer, {
         LOGOUT }     from "./customer";
import categories     from "./categories";
import giftCategory   from "./giftCategory";
import messages       from "store-reducers/messages";
import modals         from "store-reducers/modals";
import order          from "store-reducers/order";
import product        from "store-reducers/product";
import view           from "store-reducers/view";
import terms          from "store-reducers/terms";

const checkoutReducer = (state, action) => action.type === LOGOUT ?
  checkout(undefined, { type: "reinit/checkout" }) :
  checkout(state, action);

const noop = x => x;

const reducers = {
  animate,
  cart,
  categories,
  checkout: checkoutReducer,
  cmsPage,
  cmsPages,
  customer,
  giftCategory,
  lang: noop,
  messages,
  modals,
  order,
  product,
  terms,
  router,
  server: noop,
  view
};

export default function reducer(state = {}, action) {
  const newState = {};

  for (let k in reducers) {
    if (reducers.hasOwnProperty(k)) {
      newState[k] = reducers[k](state[k], action);
    }
  }

  return newState;
};
