// @flow

import { h }             from "preact";
import { PureComponent,
         ScrollTop }     from "shared-components";
import styles            from "./styles.scss";
import classNames        from "classnames";
import { connect }       from "preact-redux";
import Helmet            from "preact-helmet";
import { parseParams }   from "location-search-string";
import { withRouter }    from "react-router-dom";
import { Redirect }      from "react-router";
import { stutter,
         slideUp,
         shortDuration,
         fadeIn,
         fadeOut }       from "helpers/animations";
import { LIST_ITEM }     from "redux/animate";
import { sortCategories,
         filterCategories,
         transformCategories } from 'helpers/categories';

import InformationBar      from "components/InformationBar";
import ProductList         from "components/ProductList";
import Wrapper             from "components/Wrapper";
import GiftCategoryHeading from "components/GiftCategoryHeading";
import CategorySelection   from "components/CategorySelection";

import { load as loadCategory } from 'redux/giftCategory';

import AppFooter from "containers/AppFooter";

const SPECIAL_CASES = [28];


type State = {
  activeCategoryId: number,
};

@connect(
  state => ({
    categories      : state.categories.data,
    category        : state.giftCategory.category,
    categoryId      : state.customer.data.categoryId,
    companyName     : state.customer.data.company ? state.customer.data.company.title : null,
    customerName    : state.customer.data.customer && state.customer.data.customer.firstname ? state.customer.data.customer.firstname : "",
    demoMode        : state.giftCategory.demo,
    hasSelectedGift : state.cart.data.items.length > 0,
    initialRender   : state.animate.initial,
    leaving         : state.animate.state === LIST_ITEM,
    products        : state.giftCategory.products,
  }),
  dispatch => ({
    loadCategory: (id) => dispatch(loadCategory(id)),
  })
)
@withRouter
export default class GiftCategory extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      activeCategoryId: props.category.id || props.categoryId
    }
  }


  setOption = (categoryId) => {
    this.setState({
      activeCategoryId: categoryId
    });

    this.props.loadCategory(categoryId);
  }

  render({ categories, category, categoryId, companyName, customerName, demoMode, hasSelectedGift, leaving, products, initialRender, history }: Props, { activeCategoryId}: State, { t }: Context) {
    if (hasSelectedGift) {
      return <Redirect to="/checkout" />
    }

    const panicMode = false;

    const filteredCategories = categories.filter(filterCategories(categoryId)).map(transformCategories).sort(sortCategories);
    const includingAllCategory = filteredCategories.length > 0 ? [{ id: categoryId, name: t('GIFT_CATEGORY.ALL_CATEGORIES')}, ...filteredCategories] : null;

    if (panicMode && parseParams(this.props.location.search).user !== "crossroads") {
      return (
        <div
            class={classNames(
              styles.block,
              styles.centered
            )}>

          <h1 class={slideUp}>
            {t("GIFT_CATEGORY.TEMPORARY")}
          </h1>
        </div>
      );
    }

    return (
      <div class={styles.block}>
        <Wrapper
            element="section">

          <header
              class={classNames(
                styles.header,
                shortDuration,
                {[fadeOut]: leaving}
              )}>

            <GiftCategoryHeading {...this.props} />
          </header>

          {includingAllCategory &&
            <CategorySelection
              onClick={this.setOption}
              categories={includingAllCategory}
              activeId={activeCategoryId}
              class={classNames(styles.categorySelection, {[shortDuration]: leaving}, {[fadeOut]: leaving})}/>
          }

          <ProductList
              categoryUrl="/"
              listName="giftCategory"
              products={products}
              stutterTimingOffset={2}
              noStutter={!initialRender && history.action === "POP"}
          />
        </Wrapper>

        <AppFooter
            style={stutter(Math.min(products.length, 20) + 2 + 4)}
            class={fadeIn}
        />

        <ScrollTop />

        <Helmet
            title={t("GIFT_CATEGORY.TITLE")}
        />
      </div>
    );
  }
}
