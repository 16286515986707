// @flow

import { h }             from "preact";
import { PureComponent,
         ScrollTop }     from "shared-components";
import styles            from "./styles.scss";
import classNames        from "classnames";
import Helmet            from "preact-helmet";
import { stutter,
         slideUp }       from "helpers/animations";
import { HttpNotFound }  from "redux-express";

import Heading from "components/Heading";

export default class NotFound extends PureComponent {
  render(props: Props, state: any, { t }: Context) {
    return (
      <div class={styles.block}>
        <div class={styles.body}>
          <div>
            <Heading
                heading={t("404.HEADING")}
                subheading={t("404.SUBHEADING")}
            />
          </div>
        </div>

        <ScrollTop />

        <Helmet title={t("404.TITLE")} />

        <HttpNotFound />
      </div>
    );
  }
}
