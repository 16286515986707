// @flow

import { jsonResponse,
         sendJson }     from "fetch-utilities";
import type { Fetch }   from "fetch-utilities";
import { push }         from "react-router-redux";

import { load as loadCategory } from "redux/giftCategory";
import { load as loadCart }     from "store-reducers/cart";
import { load as loadCategories } from "redux/categories";

import { SUCCESS as CHECKOUT_SUCCESS } from "store-reducers/checkout";

export const PENDING     = "store/customer/PENDING";
export const LOADING     = "store/customer/LOADING";
export const LOADED      = "store/customer/LOADED";
export const ERROR       = "store/customer/ERROR";
export const LOGOUT      = "store/customer/LOGOUT";
export const LOGGING_IN  = "store/customer/LOGGING_IN";
export const LOGGING_OUT = "store/customer/LOGGING_OUT";

const initialState = {
  state    : PENDING,
  data     : {},
  loggedIn : false,
};

export default function reducer(state: ReduxData<*> = initialState, action: ReduxAction = {}) {
  switch (action.type) {
    case LOGGING_IN:
    case LOGGING_OUT:
    case LOADING:
      return {
        ...state,
        state: action.type
      };

    case LOADED:
      return {
        state    : action.error ? ERROR : action.type,
        data     : action.error ? state.data : action.payload,
        error    : action.error || action.payload.loginFailure,
        loggedIn : action.error ? false : !action.payload.loginFailure,
      };

    case CHECKOUT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          status: "USED"
        }
      };

    case LOGOUT:
      return initialState;

    default:
      return state;
  }
}

const handleError = err => !err.httpOk && err.httpStatus === 404 ? { ...err, loginFailure: true } : Promise.reject(err);

const loadNecessaryData = (dispatch: Function) => (resp: Object) => {
  dispatch(loadCategory(resp.categoryId));
  dispatch(loadCategories());
  dispatch(loadCart());
  return resp;
}

export const load = () => (dispatch: Function, _getState: Function, client: Object) => {
  dispatch({
    type: LOADING
  });

  dispatch({
    type:     LOADED,
    payload:  client("/julklappsvalet").then(jsonResponse).then(loadNecessaryData(dispatch)).catch(handleError)
  });
};

export const login = (data: Object) => (dispatch: Function, _getState: Function, client: Object) => {
  dispatch({
    type: LOGGING_IN
  });

  dispatch({
    type:    LOADED,
    payload: client("/julklappsvalet", sendJson({ method: "PUT", body: data })).then(jsonResponse).then(loadNecessaryData(dispatch)).catch(handleError)
  });
};

export const logout = (withCode?: boolean) => (dispatch: Function, _getState: Function, client: Fetch) => {
  dispatch({
    type: LOGGING_OUT
  });

  dispatch({
    type:     LOGOUT,
    payload:  client(withCode ? "/julklappsvalet" : "/customer", { method: "DELETE" })
  });
};
