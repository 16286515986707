// @flow

import { h }                  from "preact";
import { ScrollTop,
         PureComponent }      from "shared-components";
import styles                 from "./styles.scss";
import classNames             from "classnames";
import { connect }            from "preact-redux";
import Helmet                 from "preact-helmet";
import { Redirect }           from "react-router";
import { getProductMeta }     from "helpers/getMetaData";
import { Link }               from "react-router-dom";
import { stutter,
         fadeIn,
         slideUp,
         slideRightOut,
         slideDownOut }       from "helpers/animations";
import { animateProductView,
         pendingAnimation,
         resetAnimationState,
         PRODUCT_VIEW,
         LIST_ITEM,
         PENDING }            from "redux/animate";
import { add as addToCart }   from "store-reducers/cart";
import { setOption,
         setCustomOption }    from "store-reducers/product";

import Button           from "components/Button";
import Wrapper          from "components/Wrapper";
import CustomOption     from "components/CustomOption";
import InformationBar   from "components/InformationBar";
import ProductList      from "components/ProductList";
import ProductOption    from "components/ProductOption";
import ProductSelection from "components/ProductSelection";

import AppFooter from "containers/AppFooter";

@connect(
  state => ({
    activeImage     : state.product.activeImage,
    added           : state.cart.addedItems.indexOf(state.product.product.id)  !== -1,
    adding          : state.cart.addingItems.indexOf(state.product.product.id) !== -1,
    cartError       : state.cart.error,
    codeExpired     : state.customer.data.status === "EXPIRED",
    codeUsed        : state.customer.data.status === "USED",
    codeValidTo     : state.customer.data.code ? state.customer.data.code.validTo : null,
    hasSelectedGift : state.cart.data.items.length > 0,
    hasSelection    : Object.keys(state.product.form.attributes).length > 0,
    itemAnimating   : state.animate.state === LIST_ITEM,
    leaving         : state.animate.state === PRODUCT_VIEW || state.animate.state === LIST_ITEM,
    loggedIn        : state.customer.loggedIn,
    pendingLeave    : state.animate.state === PENDING,
    product         : state.product.product,
    productForm     : state.product.form,
    products        : state.giftCategory.products,
  }),
  dispatch => ({
    addToCart           : p        => dispatch(addToCart(p)),
    animateProductView  : (p, url) => dispatch(animateProductView(p, url)),
    resetAnimationState : ()       => dispatch(resetAnimationState),
    setAnimationPending : ()       => dispatch(pendingAnimation),
    setCustomOption     : (o, v)   => dispatch(setCustomOption(o, v)),
    setOption           : (o, v)   => dispatch(setOption(o, v)),
  })
)
export default class ProductView extends PureComponent {
  handleSubmit = (event: Event) => {
    event.preventDefault();

    if (this.submitDisabled()) {
      return;
    }

    this.props.setAnimationPending();

    this.props.addToCart({
      ...this.props.productForm,
      qty     : 1,
      product : this.props.product
    });
  }

  getActiveCustomOptions = ({ product, productForm }) => !product.options ? [] :
    product.options
      .reduce((a, o) => [...a, ...o.values], [])
      .filter(v =>
        v.customOptions &&
        Object.values(productForm.attributes)
          .map(a => a|0)
          .indexOf(v.id) !== -1)
      .reduce((a, v) => [...a, ...v.customOptions], []);

  submitDisabled = () => {
    const {
      adding,
      codeExpired,
      codeUsed,
      hasSelection,
      leaving,
      loggedIn,
      product,
      productForm,
    } = this.props;

    const copts                 = productForm.customOptions;
    const requiredCustomOptions = this.getActiveCustomOptions(this.props).filter(o => o.required);

    return (adding || leaving) ||
           (product.type === "configurable" && !hasSelection) ||
           !loggedIn ||
           !product.isSalable ||
           codeUsed || codeExpired ||
           (requiredCustomOptions.some(o =>
              !copts[o.id] ||
              (o.maxLength && copts[o.id] && copts[o.id].length > o.maxLength)))
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.hasSelectedGift && !this.props.hasSelectedGift) {
      this.props.animateProductView(this.props.product);
    }

    if (nextProps.cartError && this.props.pendingLeave) {
      this.props.resetAnimationState();
    }
  }

  render({ activeImage, added, adding, codeExpired, codeUsed, codeValidTo, hasSelectedGift, hasSelection, itemAnimating, leaving, loggedIn, pendingLeave, product, products, productForm, setCustomOption, setOption }: Props, state: any, { t }: Context) {
    if (hasSelectedGift && !leaving && !pendingLeave) {
      return <Redirect to="/checkout" />
    }

    const meta                = getProductMeta(product);
    const attributes          = product.attributes || {};
    const highlightedProducts = products.filter((p, i) => p.id !== product.id && ((i + 2) % 6 === 0 || (i + 2) % 6 === 1))
    const activeCustomOptions = this.getActiveCustomOptions(this.props);

    return (
      <div class={styles.block}>
        <Wrapper>
          <div>
            <section class={styles.body}>
              <h1 itemprop="name"
                  class={classNames(
                    styles.name,
                    "h2",
                    {[slideDownOut]: leaving}
                  )}>

                <span
                    style={{ display: "inline-block" }}
                    class={slideUp}>

                  {product.name}
                </span>

                {product.attributes.manufacturer ?
                  <span
                      style={stutter(1)}
                      class={classNames(
                        slideUp,
                        "subheading"
                      )}>

                    {product.attributes.manufacturer}
                  </span> : null
                }
              </h1>

              { !codeUsed && !codeExpired &&
                product.attributes.display_low_stock_warning|0 &&
                product.stockManage &&
                product.isSalable  &&
                product.stockQty   &&
                product.stockQty < (product.attributes.low_qty_below ? product.attributes.low_qty_below|0 : 30) ?

                <InformationBar
                    modifiers="text"
                    style={leaving ? null : stutter(2)}
                    class={classNames(
                      "row",
                      { [slideDownOut] :  leaving,
                        [slideUp]      : !leaving}
                    )}>

                  <span class={styles.alertText}>
                    {t("PRODUCT.LOW_STOCK")}
                  </span>
                </InformationBar>
              : null}

              { !product.isSalable &&
                !codeUsed &&
                !codeExpired ?

                <InformationBar
                    modifiers="text"
                    style={leaving ? null : stutter(2)}
                    class={classNames(
                      "row",
                      styles.outofstock,
                      { [slideDownOut] :  leaving,
                        [slideUp]      : !leaving}
                    )}>

                  {t("PRODUCT.REPLACEMENT")}
                </InformationBar>
              : null}

              <aside
                  class={classNames(
                    styles.aside,
                    {[slideRightOut]: leaving}
                  )}>

                <div class={styles.imageWrap}>
                  <img
                      alt=""
                      src={activeImage || product.largeImage}
                      style={stutter(2)}
                      class={classNames(
                        styles.image,
                        slideUp
                      )}
                  />
                </div>
              </aside>

              <form
                  class={styles.form}
                  onSubmit={this.handleSubmit}>

                {product.selections ?
                  <div
                      style={leaving ? null : stutter(2)}
                      class={classNames(
                        "row",
                        styles.formRow,
                        { [slideUp]      : !leaving,
                          [slideDownOut] :  leaving }
                      )}>

                    {product.selections.map(selection =>
                      <ProductSelection
                          selection={selection}
                          value={Object.keys(productForm.bundleOptions).length > 0 ? productForm.bundleOptions[selection.id] : null}
                          name="bundle_option"
                          disabled={adding || leaving || !product.isSalable}
                      />
                    )}
                  </div>
                : null}

                <div
                    class={classNames(
                      styles.formRow,
                      {[styles.hasOptions]: product.options}
                    )}>

                  {(product.options || product.customOptions) ?
                    <div
                        style={leaving ? null : stutter(2)}
                        class={classNames(
                          styles.options,
                          { [slideUp]      : !leaving,
                            [slideDownOut] :  leaving }
                        )}>

                      {product.options && product.options.map(option =>
                        <ProductOption
                            disabled={adding || leaving || !product.isSalable}
                            option={option}
                            class={styles.option}
                            setOption={setOption}
                            selectedValue={Object.keys(productForm.attributes).length > 0 ? productForm.attributes[option.id] : null}
                        />
                      )}

                      {(product.customOptions || []).concat(activeCustomOptions).map(option =>
                        <CustomOption
                            option={option}
                            onInput={setCustomOption}
                            disabled={adding || leaving || !product.isSalable}
                            value={productForm.customOptions[option.id]}
                            autoFocus={true}
                            class={classNames(
                              "row",
                              styles.customOption,
                            )}
                        />
                      )}
                    </div>
                  : null}

                  <Button
                      type="submit"
                      modifiers="primary flat"
                      disabled={this.submitDisabled()}
                      style={leaving ? null : stutter(2)}
                      class={classNames(
                        styles.button,
                        { [slideUp]         : !leaving,
                          [slideDownOut]    : leaving,
                          [styles.inactive] : !product.isSalable }
                      )}>

                    {adding || added ?
                       t("PRODUCT.ADDING") :
                       t("PRODUCT.ADD_TO_CART")}
                  </Button>

                  {codeUsed || codeExpired || !loggedIn ?
                    <InformationBar
                        modifiers="text"
                        style={leaving ? null : stutter(8)}
                        class={classNames(
                          "row",
                          styles.expired,
                          { [slideDownOut] :  leaving,
                            [fadeIn]       : !leaving }
                        )}>

                      {!loggedIn ?
                        t("PRODUCT.DEMO") :
                        (codeUsed ?
                          t("PRODUCT.CODE_USED") :
                          t("PRODUCT.CODE_EXPIRED", {
                            expiredAt: codeValidTo
                          }))}
                    </InformationBar>
                  : null}
                </div>
              </form>

              {product.description ?
                <div
                    dangerouslySetInnerHTML={{__html: product.description}}
                    style={leaving ? null : stutter(3)}
                    class={classNames(
                      styles.description,
                      "typography",
                      { [slideDownOut] :  leaving,
                        [slideUp]      : !leaving }
                    )}
                />
              : null}

              <div
                  style={leaving ? null : stutter(4)}
                  class={classNames(
                    slideUp,
                    "row--huge",
                    {[slideDownOut]: leaving}
                  )}>

                <p>
                  <Link to="/">
                    {t("PRODUCT.GO_BACK")}
                  </Link>
                </p>
              </div>
            </section>
          </div>

          {highlightedProducts.length > 0 &&
            <section
                class={classNames(
                  "row--section",
                  {[slideDownOut]: leaving && !itemAnimating}
                )}>

              <h1 style={leaving ? null : stutter(5)}
                  class={classNames(
                    "h2",
                    slideUp,
                    {[slideDownOut]: leaving}
                  )}>

                {t("PRODUCT.POPULAR")}
              </h1>

              <ProductList
                  categoryUrl="/"
                  listName="giftCategory"
                  stutterTimingOffset={6}
                  products={highlightedProducts}
                  style={stutter(6)}
                  shouldAnimate={!leaving}
                  class={classNames(
                    "row--huge",
                    {[slideUp]: !leaving}
                  )}
              />
            </section>
          }

        </Wrapper>

        <AppFooter
            style={stutter(highlightedProducts.length + 6 + 4)}
            class={fadeIn}
        />

        <ScrollTop />

        <Helmet
            title={meta.title}
            meta={meta.data}
            link={meta.link}
        />
      </div>
    );
  }
}
