 // @flow

import { h }             from "preact";
import { PureComponent } from "shared-components";
import styles            from "./styles.scss";
import classNames        from "classnames";

type Props = {
  class?: string
};

export default class NumberedList extends PureComponent {
  props: Props;

  render({ children }: Props) {
    if (children.length === 0) {
      return null;
    }

    return (
      <div
          class={classNames(
            this.props["class"],
            styles.block,
          )}>

        <ol class={styles.list}>
          {children.filter(c => c).map((child, i) =>
            <li {...child.attributes}
                class={classNames(
                  styles.item,
                  child.attributes.class
                )}>

              <div class={styles.media}>
                <span class={styles.number}>
                  {i + 1}
                </span>
              </div>

              <div class={styles.child}>
                {child.children}
              </div>
            </li>
          )}
        </ol>
      </div>
    );
  }
}
