// @flow

import { h }             from "preact";
import { PureComponent } from "shared-components";

import Dropdown from "components/Dropdown";

type Props = {
  class?        : string,
  selection     : Object,
  selectedValue : Object,
};

export default class ProductSelection extends PureComponent {
  render({ selection }: Props) {
    switch(selection.type) {
      case "checkbox": return <Hidden {...this.props} />; // TODO: implement
      case "hidden":   return <Hidden {...this.props} />;
      case "multi":    return null; // TODO: implement
      case "radio":    return null; // TODO: implement
      case "select":   return null; // TODO: implement
      default:         return null; // 🤔
    }
  }
}

const Hidden = ({ selection, name, ...rest }) => {
  return (
    <div>
      <input
          {...rest}
          name={`${name}[${selection.id}]`}
          type="hidden"
      />

      <input
          {...rest}
          name={`${name}_qty[${selection.id}]`}
          type="hidden"
      />
    </div>
  );
}
