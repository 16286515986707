// @flow

import { h }                from "preact";
import { PureComponent }    from "shared-components";
import classNames           from "classnames";
import { stutter, slideUp } from "helpers/animations";

const SPECIAL_CASES = [28];

export default class GiftCategoryHeading extends PureComponent {
  render({ category, categoryId, companyName, customerName, demoMode }: Props, state: any, { t }: Context) {
    return (
      <h1>
        <span
            class={slideUp}
            style={{ display: "inline-block" }}>

          {demoMode ?
            t("GIFT_CATEGORY.DEMO.HEADING") :
            SPECIAL_CASES.indexOf(categoryId) !== -1 ?
              t("GIFT_CATEGORY.SPECIAL.HEADING") :
              customerName ?
                t("GIFT_CATEGORY.GREETING.HEADING", {
                  name: customerName
                }) :
                t("GIFT_CATEGORY.HEADING")}
        </span>

        <span
            style={stutter(1)}
            class={classNames(
              "subheading",
              slideUp
            )}>

          {demoMode ?
            category.name :
            SPECIAL_CASES.indexOf(categoryId) !== -1 ?
              t("GIFT_CATEGORY.SPECIAL.SUBHEADING", {
                companyName: companyName
              }) :
              customerName ?
                t("GIFT_CATEGORY.GREETING.SUBHEADING", {
                  name: customerName
                }) :
                t("GIFT_CATEGORY.SUBHEADING")}
        </span>
      </h1>
    );
  }
}
