// @flow

import { h }                     from "preact";
import { PureComponent }         from "shared-components";
import styles                    from "./styles.scss";
import classNames                from "classnames";
import { connect }               from "preact-redux";
import { Link, NavLink }         from "react-router-dom";
import { toggle as toggleModal } from "store-reducers/modals";
import { hintCmsPage }           from "redux/cmsPage";

import AppLogo    from "components/AppLogo";
import Button     from "components/Button";
import Hamburger  from "components/Hamburger";
import PrimaryNav from "components/PrimaryNav";

import DemoBar       from "containers/DemoBar";
import OffCanvasMenu from "containers/OffCanvasMenu";

type Props = {
  class?: string
};

@connect(
  state => ({
    cmsPages     : state.cmsPages.data,
    customerData : state.customer.data,
    demoMode     : state.giftCategory.demo,
    hasOrder     : state.order.data.id || state.customer.data && state.customer.data.status === "USED",
    location     : state.router,
    loggedIn     : state.customer.loggedIn,
    menuOpen     : state.modals.indexOf("OCM") !== -1,
    pathname     : state.router.location ? state.router.location.pathname : state.server.pathname,
  }),
  dispatch => ({
    hintCmsPage : ({ urlKey }) => dispatch(hintCmsPage({ urlKey })),
    toggleMenu  : () => dispatch(toggleModal("OCM"))
  })
)
export default class AppHeader extends PureComponent {
  props: Props;

  render({ cmsPages, customerData, demoMode, hasOrder, hintCmsPage, location, loggedIn, menuOpen, pathname, toggleMenu }: Props, state: any, { t }: Context) {
    const filteredCmsPages  = cmsPages.filter(p => p.includeInMenu);

    return (
      <header
          class={classNames(
            this.props["class"],
            styles.block
          )}>

        {demoMode ?
          <DemoBar />
        : null}

        <div class={styles.bar}>
          <section class={styles.wrapper}>
            <AppLogo
                class={styles.logo}
            />

            <Hamburger
                onClick={toggleMenu}
                open={menuOpen}
                title={menuOpen ? t("MENU.CLOSE") : t("MENU.OPEN")}
                class={styles.menuToggle}
            />

            <PrimaryNav class={styles.nav}>
              {hasOrder ?
                <NavLink
                    to="/checkout/success"
                    activeClassName={styles.active}
                    className={classNames(
                      styles.link,
                      styles.navLink
                    )}>

                  {t("SUCCESS.LABEL")}
                </NavLink>
              : null}

              {filteredCmsPages.map(page =>
                <NavItem
                    hintCmsPage={hintCmsPage}
                    page={page}
                    location={location}
                />
              )}

              {loggedIn ?
                <Link
                    to={t("LOGOUT.HREF")}
                    class={classNames(
                      styles.link,
                      styles.navLink
                    )}>

                  {t("LOGOUT.TEXT")}
                </Link>
              : <NavLink
                    to={t("LOGIN.LINK.HREF")}
                    activeClassName={styles.active}
                    className={classNames(
                      styles.link,
                      styles.navLink
                    )}>

                  {t("LOGIN.LINK.TEXT")}
                </NavLink>
              }

              {loggedIn && (customerData.firstname || customerData.company) ?
                <b>
                  {customerData.firstname ? `${customerData.firstname} ${customerData.lastname}` : customerData.company.title}
                </b>
              : null}
            </PrimaryNav>
          </section>
        </div>

        <OffCanvasMenu />
      </header>
    );
  }
}

class NavItem extends PureComponent {
  handleClick = () => this.props.hintCmsPage(this.props.page);

  render({ children, page }) {
    return (
      <NavLink
          to={`/${page.urlKey}`}
          activeClassName={styles.active}
          className={classNames(
            styles.link,
            styles.navLink
          )}>

        {page.title}
      </NavLink>
    );
  }
}
