const CMS_TYPE = "cmspage";

export const PENDING = "store/cms/PENDING";
export const LOADED  = "store/cms/LOADED";

import {
  VIEW_LOADED,
  HINT,
  hint
} from "store-reducers/view";

const initialState = {
  state: PENDING,
  data:  {}
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case VIEW_LOADED:
      if (action.error || action.payload.type !== CMS_TYPE) {
        return state;
      }

      return {
        state: LOADED,
        data:  action.payload.cmsPage,
      };

    case HINT:
      if (action.hintType !== CMS_TYPE) {
        return state;
      }

      return {
        state: LOADED,
        data:  action.hintData,
      };

    default:
      return state;
  }
}

export const hintCmsPage = page => hint(CMS_TYPE, page.urlKey, page);
