// @flow

import { h }             from "preact";
import { PureComponent } from "shared-components";
import styles            from "./styles.scss";
import classNames        from "classnames";
import { slideDown,
         shortDuration } from "helpers/animations";

import Message from "components/Message";

export default class InputError extends PureComponent {
  props: Props;

  render({ errors, name }: Props, state: any, { t }: Context) {
    if (!errors || errors.length === 0) {
      return;
    }

    return (
      <Message
          element="label"
          status="error"
          for={name}
          class={classNames(
            this.props["class"],
            styles.block,
            slideDown,
            shortDuration
          )}>

        {errors.map(err =>
          <span>
            {err.error === "MATCH" || err.error === "LENGTH_GT" ?
              t(`VALIDATION.${err.error}.${err.field.toUpperCase()}`) :
              t(`VALIDATION.${err.error}`)}
          </span>
        )}
      </Message>
    );
  }
}
