// @flow

import { h }                   from "preact";
import { PureComponent }       from "shared-components";
import styles                  from "./styles.scss";
import classNames              from "classnames";
import { connect }             from "preact-redux";
import { Link, NavLink }       from "react-router-dom";
import { close as closeModal } from "store-reducers/modals";
import { stutter,
         shortDuration,
         slideRight,
         fadeIn,
         fadeOut }             from "helpers/animations";

import Button  from "components/Button";
import Wrapper from "components/Wrapper";

const SCROLL_KEYS = [9, 32, 33, 34, 35, 36, 37, 38, 39, 40];
const MODAL_NAME  = "OCM";

@connect(
  state => ({
    cmsPages     : state.cmsPages.data,
    customerData : state.customer.data,
    hasOrder     : state.order.data.id || state.customer.data && state.customer.data.status === "USED",
    loggedIn     : state.customer.loggedIn,
    openModals   : state.modals,
  }),
  dispatch => ({
    close : () => dispatch(closeModal("OCM"))
  })
)
export default class OffCanvasMenu extends PureComponent {
  context:  Context;
  props:    Props;

  eventIsInComponent = (event: Event) => this.offCanvasMenu && this.offCanvasMenu.contains(event.target);

  preventScroll = (event: Event) => {
    if (!this.eventIsInComponent(event)) {
      event.preventDefault();
    }
  };

  handleKeydown = (event: Event) => {
    if (event.which === 27) {
      this.props.close();
    }

    if (!this.eventIsInComponent(event) && SCROLL_KEYS.indexOf(event.which) !== -1) {
      event.preventDefault();
    }
  }

  setListeners = () => {
    if (!document) {
      return;
    }

    setTimeout(() => {
      this.offCanvasMenu && this.offCanvasMenu.focus();
    }, 0);

    document.addEventListener("wheel",      this.preventScroll);
    document.addEventListener("touchmove",  this.preventScroll);
    document.addEventListener("keydown",    this.handleKeydown);

    window.addEventListener("resize", this.props.close);

    if (document.scrollingElement) {
      document.scrollingElement.style.overflow = "hidden";
    }
  }

  removeListeners = () => {
    if (!document) {
      return;
    }

    document.removeEventListener("wheel",     this.preventScroll);
    document.removeEventListener("touchmove", this.preventScroll);
    document.removeEventListener("keydown",   this.handleKeydown);

    window.removeEventListener("resize", this.props.close);

    if (document.scrollingElement) {
      document.scrollingElement.style.removeProperty("overflow");
    }
  }

  componentWillReceiveProps(nextProps) {
    /*
       this could be lifecycle methods of the modal component, but
       willUnmount doesn't run until after rc-animate has finished
       animating, causing an unwanted delay of ability to scroll
    */

    if (this.props.openModals.indexOf(MODAL_NAME) !== nextProps.openModals.indexOf(MODAL_NAME)) {
      nextProps.openModals.indexOf(MODAL_NAME) !== -1 ? this.setListeners() : this.removeListeners();
    }
  }

  componentWillUnmount() {
    this.removeListeners();
  }

  render({ cmsPages, customerData, hasOrder, loggedIn, openModals }: Props, { showCategories }: any, { t }: Context) {

    const initialStutter = 1;

    if (openModals.indexOf(MODAL_NAME) !== -1) {
      return (
        <nav
            ref={elem => this.offCanvasMenu = elem}
            key="ocm"
            tabindex="-1"
            class={classNames(
              styles.block,
              shortDuration,
              fadeIn
            )}>

          <Wrapper class={styles.wrapper}>
            <ul>
              {cmsPages.filter(p => p.includeInMenu).map((page, i) =>
                <li style={stutter(initialStutter + i)}
                    class={classNames(
                      styles.item,
                      slideRight
                    )}>

                  <NavLink
                      to={`/${page.urlKey}`}
                      className={styles.link}
                      activeClassName={styles.active}>

                    {page.title}
                  </NavLink>
                </li>
              )}

              <li style={stutter(initialStutter + cmsPages.length)}
                  class={classNames(
                    styles.item,
                    slideRight
                  )}>

                <Link
                    to="/"
                    class={styles.link}>

                  {t("OCM.HOME")}
                </Link>
              </li>

              {loggedIn ?
                <li style={stutter(initialStutter + cmsPages.length + 1)}
                    class={classNames(
                      styles.item,
                      slideRight
                    )}>

                  <Link
                      to={t("LOGOUT.HREF")}
                      class={styles.link}>

                    {t("LOGOUT.TEXT")}
                  </Link>
                </li>
              : <li style={stutter(initialStutter + cmsPages.length + 1)}
                  class={classNames(
                    styles.item,
                    slideRight
                  )}>

                  <Link
                      to={t("LOGIN.LINK.HREF")}
                      class={styles.link}>

                    {t("LOGIN.LINK.TEXT")}
                  </Link>
                </li>
              }

              {hasOrder ?
                <li style={stutter(initialStutter + cmsPages.length + 2)}
                    class={classNames(
                      styles.item,
                      slideRight
                    )}>

                  <NavLink
                      to="/checkout/success"
                      activeClassName={styles.active}
                      className={styles.link}>

                    {t("SUCCESS.LABEL")}
                  </NavLink>
                </li>
              : null}
            </ul>

            {loggedIn && (customerData.firstname || customerData.company) ?
              <section class={styles.section}>
                <div
                    style={stutter(initialStutter + cmsPages.length + (hasOrder ? 3 : 2))}
                    class={classNames(
                      styles.item,
                      styles.name,
                      slideRight
                    )}>

                  {customerData.firstname ? `${customerData.firstname} ${customerData.lastname}` : customerData.company.title}
                </div>
              </section>
            : null}
          </Wrapper>
        </nav>
      )
    }
  }
}
